import * as React from "react";
import { useState } from 'react'
import styled from 'styled-components'
import Hero from './Hero'
import LetsBuildSec from './LetsBuildSec/LetsBuildSec'
import WeHelpSec from './WeHelpSec/WeHelpSec'
import FormSec from './FormSec'
import Theme from '../../../vars/ThemeOptions' 
import { Page } from "../../../classes/Page";
import { HeroContent } from "../../../classes/HeroContent";
import { Section } from "../../../classes/Section";

const Wrap = styled.div`
  font-family: Rajdhani;
  color: ${Theme.hex('secondary')};
  overflow: hidden;
`

const leftRightPad = "7rem";
const leftRightPadMobile = "2rem";
const borderThickness = "30px";
const contentWidth = "90%";

export interface ContObjType { title: string; }


const AECCampaignPage = ({ page }: { page: Page }) => {
  const [showForm, setShowForm] = useState(false)
  const toggleForm = () => setShowForm(!showForm)
  
  // console.log("page: ", page)
  // isolating data for each section
  const content = page.content;
  const heroData = new HeroContent(content.references.find((obj: ContObjType) => { return obj.title === "Win The Whole Build"}));
  const letsBuildSecData = new Section(content.references.find((obj: ContObjType) => { return obj.title === "Let's Build The Bridge Together"}));
  const weHelpSecData = new Section(content.references.find((obj: ContObjType) => { return obj.title === "We Help Companies Build"}));
  // const downloadFormSecData = content.references.find(obj => { return obj.title === "Download Your Inbound Blueprint Form"});
  const formSecData = new Section(content.references.find((obj: ContObjType) => { return obj.title === "AEC Form Section" }));
  return (
    <Wrap>
      <Hero heroData={heroData} lrPad={leftRightPad} lrPadMob={leftRightPadMobile} bdrThck={borderThickness} contWd={contentWidth} />
      <LetsBuildSec secData={letsBuildSecData} lrPad={leftRightPad} lrPadMob={leftRightPadMobile} bdrThck={borderThickness} toggleForm={toggleForm} />
      <WeHelpSec secData={weHelpSecData} />
      <FormSec secData={formSecData} showForm={showForm} toggleForm={toggleForm} />
    </Wrap>
  )
}
 
export default AECCampaignPage