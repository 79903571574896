import * as React from "react";
import styled from "styled-components";
import Theme from "../../../../vars/ThemeOptions";
import Corner from "./CtaCorner";

const cornerOffset = "25px";
const cornerHoverOffset = "10px";

const Wrap = styled.div<{
  botRight?: boolean;
}>`
  margin: 30px 0;
  button {
    padding: 0.5rem 1rem;
  }
  a {
    padding: 1rem 2rem;
  }
  button,
  a {
    display: block;
    position: relative;
    background: ${Theme.hex("secondary")};
    border: none;
    margin-left: ${cornerOffset};
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    p {
      margin-bottom: 0;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      :first-of-type {
        margin-bottom: -5px;
      }
    }
    .corner {
      &.topLeft {
        top: -${cornerOffset};
        left: -${cornerOffset};
        transition: all 0.3s ease;
      }
      &.botRight {
        bottom: -${cornerOffset};
        right: -${cornerOffset};
        transition: all 0.3s ease;
      }
    }
    &:hover {
      text-decoration: none;
      color: white;
      .corner {
        &.topLeft {
          top: -${cornerHoverOffset};
          left: -${cornerHoverOffset};
          transition: all 0.3s ease;
        }
        &.botRight {
          bottom: -${cornerHoverOffset};
          right: -${cornerHoverOffset};
          transition: all 0.3s ease;
        }
      }
    }
  }
`;

interface CtaProps {
  textLine1: string;
  textLine2?: string;
  purpose: string;
  toggleForm?: () => void;
}

const AecCtaButton = ({
  textLine1,
  textLine2,
  purpose,
  toggleForm
}: CtaProps) => {
  let JSX;
  switch (purpose) {
    case "goToForm":
      JSX = (
        <button onClick={() => toggleForm()}>
          <Corner />
          <Corner botRight />
          <p>{textLine1}</p>
          <p>{textLine2}</p>
        </button>
      );
      break;
    case "fileDownload":
      JSX = (
        <a
          href="/Building_Inbound.pdf"
          download
          target="_blank"
          rel="noopener noreferrer">
          <Corner />
          <Corner botRight />
          <p>{textLine1}</p>
          <p>{textLine2}</p>
        </a>
      );
      break;
    case "bookMeeting":
      JSX = (
        <a
          href="https://meetings.hubspot.com/darby-kim"
          target="_blank"
          rel="noopener noreferrer">
          <Corner />
          <Corner botRight />
          {textLine1}
        </a>
      );
      break;
    default:
      JSX = null;
      break;
  }

  return <Wrap>{JSX}</Wrap>;
};

export default AecCtaButton;
