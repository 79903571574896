import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HubspotForm } from "wmk-hubspot-form";
import styled from "styled-components";
import { RichTextReact } from "wmk-rich-text";
import options from "../../../richText/handlers/richTextOptions";
import { WmkImage } from "wmk-image";
import { Section } from "../../../../classes/Section";

const Wrap = styled.div`
  position: relative;
  color: #56659c;
  overflow: hidden;
  .container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    .copy-wrap {
      h2 {
        font-size: 2.7rem;
      }
      ul li {
        margin-top: -10px;
      }
    }
    .preview-wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    #dl-row {
      padding-top: 1rem;
      .dl-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p:last-of-type {
          text-align: center;
          a {
            color: #56659c;
            font-weight: medium;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .form-wrap {
    .hbspt-form {
      // padding-left: 3rem;
      // margin-top: -1rem;
      .form-columns-1,
      .form-columns-2 {
        input {
          width: 100% !important;
        }
      }
      input {
        background: none;
        border: none;
        border-bottom: 1px solid #223973;
        width: 100%;
        :focus {
          outline: none;
        }
      }
      input[type="submit"] {
        border: none;
        background: #2b67b9;
        color: white;
        width: 13rem;
        padding-top: 5px;
        border-radius: 50px;
        font-size: 1.5rem;
        opacity: 1;
        transition: opacity 0.3s ease;
        &:hover {
          opacity: 0.8;
          transition: opacity 0.3s ease;
        }
      }
      // .hs-submit {
      //   text-align: center;
      // }
      h3 {
        text-align: left;
        font-size: 1.3rem;
        margin: 1.5rem 0 1rem 0;
      }
      .hs-error-msg {
        margin-top: 10px;
        margin-bottom: 0;
      }
      .hs-form-required {
        display: none;
      }
    }
  }
  .bg-wrap {
    position: absolute;
    z-index: -1;
    top: 1%;
    right: 0;
    width: 45%;
    height: 100%;
    .gatsby-image-wrapper {
      width: 60vw;
      height: 100%;
    }
    .lighten-bg-top-bot-overlay {
      position: absolute;
      // z-index: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        to bottom,
        white 0%,
        rgba(255, 255, 255, 0) 40%
      );
    }
  }
  @media only screen and (max-width: 2100px) and (min-width: 1501px) {
    .bg-wrap {
      width: 45%;
      height: 100%;
      .gatsby-image-wrapper {
        width: 80vw;
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 1500px) and (min-width: 992px) {
    .bg-wrap {
      width: 50%;
      height: 120%;
      top: -19%;
      .gatsby-image-wrapper {
        width: 130vw;
      }
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .bg-wrap {
      width: 50%;
      height: 130%;
      top: -29%;
      right: -7%;
      .gatsby-image-wrapper {
        width: 170vw;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    text-shadow: 1px 1px 1px white;
    .bg-wrap {
      opacity: 0.1;
      width: 100%;
      height: 100%;
      top: 0%;
      right: 0%;
      .gatsby-image-wrapper {
        width: 130%;
      }
    }
    // .bg-wrap {
    //   display: none;
    // }
  }
`;

const IncreaseFlowSection = ({
  sectionData,
  handleElement
}: {
  sectionData: Section;
  handleElement: (element: HTMLDivElement) => void;
}) => {
  // console.log("data from flow: ", data)
  const background = sectionData.image1;
  const mainCopy = sectionData.copy1;
  const dlCopy = sectionData.copy2;
  const dlPreview = sectionData.image2;
  const formDiv = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    const element = formDiv.current;
    handleElement(element);
  });
  return (
    <Wrap>
      <Container>
        <Row>
          <Col xs={12} md={7}>
            <div className="copy-wrap">
              {mainCopy && (
                <RichTextReact content={mainCopy} options={options} />
              )}
            </div>
          </Col>
        </Row>
        <Row id="dl-row">
          <Col xs={4} md={2}>
            <div className="preview-wrap">
              {dlPreview && <WmkImage image={dlPreview} />}
            </div>
          </Col>
          <Col xs={8} md={4}>
            <div className="dl-wrap">
              {dlCopy && <RichTextReact content={dlCopy} options={options} />}
            </div>
          </Col>
        </Row>
        <Row id="form-row">
          <Col xs={12} md={6}>
            <div className="form-wrap" ref={formDiv}>
              <HubspotForm
                portalId="526959"
                formId="f5994548-3048-480c-a11a-d395dac62b87"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bg-wrap">
        <WmkImage image={background} />
        <div className="lighten-bg-top-bot-overlay" />
      </div>
    </Wrap>
  );
};

export default IncreaseFlowSection;
