import * as React from "react";
import styled from 'styled-components'
import { RichText, RichTextReact } from "wmk-rich-text";
import options from "../../../richText/handlers/richTextOptions";
 
const Wrap = styled.div`
  background: linear-gradient(to right, #D54320, #E3841C);
  padding: 1rem 0;
  color: white;
  text-align: center;
  margin: 4px 0;
  h4, p {
    text-transform: uppercase;
  }
  h4 {
    font-size: 3rem;
    font-weight: 200;
  }
  p {
    font-size: 1.3rem;    
    font-weight: 600;
    margin-bottom: 0;
  }
`
 
const WeHelpBanner = ({ copy }: { copy: RichText }) => {
  return (
    <Wrap>
      <RichTextReact content={copy} options={options} />
    </Wrap>
  )
}
 
export default WeHelpBanner