import * as React from "react";
import styled from 'styled-components'
import Theme from '../../../../vars/ThemeOptions'


const size = "40px";
const thickness = "35%";
const Corner = styled.div<{
  botRight?: boolean;
}>`
  width: ${size};
  height: ${size};
  background: ${Theme.hex('primary')};
  clip-path: polygon(0% 0%, 100% 0%, 100% ${thickness}, ${thickness} ${thickness}, ${thickness} 100%, 0% 100%);
  position: absolute;
  ${({ botRight }) => botRight ? `transform: rotate(180deg);` : ``}
`
 
const CTACorner = ({ hovered, botRight }: { hovered?: boolean; botRight?: boolean; }) => {
  return (
    <Corner className={botRight ? "corner botRight" : "corner topLeft"} botRight={botRight} />
  )
}
 
export default CTACorner


// NOTE: hover animation for the corners is handled in the parent component (CTA.js)