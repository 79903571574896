import React, { useState } from 'react'
import styled from 'styled-components'
import Row1 from './Row1'
import Row2 from './Row2'
import Row3 from './Row3'
import Row4 from './Row4'
import Form from './Form'
import { Img } from 'wmk-image'
import { Page } from '../../../../classes/Page'
import { ContObjType } from '../../AECCampaign/AECCampaign'
import { Section } from '../../../../classes/Section'
 
const Wrap = styled.div`
  position: relative;
  h1, h2, h3, h4, h5, h6 {
    font-family: Rajdhani, "Nunito Sans", Arial;
  }
  p {
    font-family: "Nunito Sans", Rajdhani, Arial;
  }
`
 
const WaterLP2 = ({ page, logo }: { page: Page; logo: Img; }) => {
  const [formOpen, setFormOpen] = useState(false);
  const toggleForm = () => setFormOpen(!formOpen);
  // console.log('page: ', page);
  const content = page.content;
  // const row1Data = pageContent[0]
  // const row2Data = pageContent[1]
  // const row3Data = pageContent[2]
  // const row4Data = pageContent[3]
  const freeWhitepaperData = new Section(content.references.find((obj: ContObjType) => { return obj.title === "Free Whitepaper"}));
  const discoverData = new Section(content.references.find((obj: ContObjType) => { return obj.title === "What You'll Learn"}));
  const viewOurWorkData = new Section(content.references.find((obj: ContObjType) => { return obj.title === "View Our Work in the Industry"}));
  const exploreData = new Section(content.references.find((obj: ContObjType) => { return obj.title === "Explore Our Automatic Solutions"}));
  return (
    <Wrap>
      <Row1 sectionData={freeWhitepaperData} formOpen={formOpen} toggleForm={toggleForm} logo={logo} />
      <Row2 sectionData={discoverData} />
      <Row3 sectionData={viewOurWorkData} />
      <Row4 sectionData={exploreData} />
      <Form formOpen={formOpen} toggleForm={toggleForm} />
    </Wrap>
  )
}
 
export default WaterLP2