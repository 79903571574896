import { Img } from "wmk-image";
import { RichText } from "wmk-rich-text";
import { HeroFields } from "../node/fragments/NodeHeroFields";

export class HeroContent {
  title: string;
  content?: RichText;
  background?: Img;
  constructor(node: HeroFields) {
    const _node = { ...node };
    this.title = _node.title;
    this.content = _node.content && new RichText(_node.content);
    this.background = _node.background && new Img(_node.background);
  }
}