import * as React from "react";
import { HubspotForm } from 'wmk-hubspot-form'
import styled from 'styled-components'
 

const Wrap = styled.div<{
  formOpen: boolean;
}>`
  position: fixed;
  top: ${({formOpen}) => formOpen ? 0 : -100000};
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: ${({formOpen}) => formOpen ? 1 : 0};
  z-index: ${({formOpen}) => formOpen ? 100000 : -100000};
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.75);
  form {
    background: white;
    padding: 2rem;
    input {
      background: none;
      border: none;
      border-bottom: 1px solid #223973; 
      width: 100%;  
      :focus {
        outline: none;
      }     
    }
    input[type="submit"] {
      border: none;
      background: #2B67B9;
      color: white;
      width: 13rem;
      padding-top: 5px;
      border-radius: 50px;
      font-size: 1.5rem;
      opacity: 1;
      transition: opacity .3s ease;
      &:hover {
        opacity: .8;
        transition: opacity .3s ease;
      }
    }
    .hs-submit {
      text-align: center;        
    }
    h3 {
      text-align: center;
      font-size: 1.3rem;
      margin: 1.5rem 0 1rem 0;
    }
    .hs-error-msg {
      margin-top: 10px;
      margin-bottom: 0;
    }
    .hs-form-required {
      display: none;
    }
  }
  button {
    position: fixed;
    top: 10vw;
    right: 10vw;
    background: none;
    color: white;
    font-weight: bold;
    font-size: 3rem;
  }
`
 
const Form = ({ formOpen, toggleForm }: { formOpen: boolean; toggleForm: ()=>void; }) => {
  return (
    <Wrap formOpen={formOpen}>
      <HubspotForm
        portalId='526959'
        formId='f7c74977-afec-4ea5-b95f-20e92649e718'
      />
      <button className="close" onClick={() => toggleForm()}>X</button>
    </Wrap>
  )
}
 
export default Form