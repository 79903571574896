import * as React from "react";
import { WmkLink } from "wmk-link";
import { RichTextNode } from "wmk-rich-text";

import { locale } from ".";

interface FieldsInt {
  entry: {
    [key: string]: {
      fields: {
        slug: { [key: string]: string };
      };
    };
  };
}

const getEntryPath = (fields: FieldsInt) => {
  const entry = fields.entry[locale];
  const slug = entry.fields.slug[locale];
  const path = "/" + slug;
  return path;
};

interface RenderEntryInt { [key: string]: ({ fields, children }: { fields: FieldsInt; children: React.ReactNode; }) => JSX.Element }

const RenderEntry: RenderEntryInt = {
  links: ({ fields, children }: { fields: FieldsInt; children: React.ReactNode; }) => {
    return <WmkLink to={getEntryPath(fields)}>{children}</WmkLink>;
  }
};

export const inlineEntryHyperlink = (
  node: RichTextNode,
  children: React.ReactNode
) => {
  const { fields } = node.data.target;
  const contentType =
    node.data.target.sys.contentType && node.data.target.sys.contentType.sys.id;

  const JSX = RenderEntry[contentType];

  return JSX ? (
    <JSX fields={fields}>{children}</JSX>
  ) : (
    <em className="hyperlink-error error">
      {children}
      {console.log("inline hyperlink " + contentType + " not set")}
    </em>
  );
};
