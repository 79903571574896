import * as React from "react";
import { WmkLink } from 'wmk-link';
import { RichTextNode } from "wmk-rich-text";
import { locale } from "."

export const inlineAssetHyperlink = (node: RichTextNode, children: React.ReactNode) => {
  const { nodeType, data } = node;
  const url = data.target.fields.file[locale].url;
  let JSX = null;
  switch (nodeType) {
    case "asset-hyperlink":
      JSX = ({ children }: { children: React.ReactNode }) => (
        <WmkLink to={url} target="self" className="inline">
          {children}
        </WmkLink>
      );
      break;
    default:
      console.log(nodeType + " not set");
  }
  return <JSX>{children}</JSX>;
};
