import * as React from "react";
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { IoMdClose } from 'react-icons/io'
import Theme from '../../../vars/ThemeOptions'
import { WmkImage } from "wmk-image";
import { HubspotForm } from "wmk-hubspot-form";
import { Section } from "../../../classes/Section";
 
const cornerSize = "40px";
const cornerThickness = "35%";
const cornerOffset = "25px";
//const cornerHoverOffset = "10px";

const Wrap = styled.div`
  background: gray;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  // increased to go over footer
  z-index: 5000;
  bottom: 0;
  .hbspt-form {
    padding: 5rem 20vw;
    position: relative;
    z-index: 10;
    .form-columns-1, .form-columns-2 {
      input {
        width: 100% !important;
      }      
    }
    ul.hs-error-msgs {
      color: red;
      padding-top: .25rem;
      margin-bottom: 0;
    }
    input {
      border: none;
      border-bottom: 2px solid black;
      padding: 3px 10px;
      background: rgba(255,255,255,0.5);
      &[name="firstname"] {
        width: 90%;
      }
    }
    input[type="submit"] {
      position: relative;
      background: ${Theme.hex('secondary')};
      border: none;
      color: white;
      margin-top: 30px;
      width: 98%;
      // margin-left: ${cornerOffset};
      text-transform: uppercase;
      font-weight: bold;
      // below css is not working to replicate the corner pieces of the cta (seen in all cta's on the main page and thank you page) - will come back to try and make this happen
      // ::before {
      //   content: "";
      //   width: ${cornerSize};
      //   height: ${cornerSize};
      //   background: ${Theme.hex('primary')};
      //   clip-path: polygon(0% 0%, 100% 0%, 100% ${cornerThickness}, ${cornerThickness} ${cornerThickness}, ${cornerThickness} 100%, 0% 100%);
      //   // position: absolute;
      // }
      transition: all .3s ease;
      &:hover {
        color: ${Theme.hex('primary')};
        transition: all .3s ease;
      }
      p {
        margin-bottom: 0;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        :first-of-type {
          margin-bottom: -5px;
        }
      }
    }
  }
  .gatsby-image-wrapper {
    position: absolute !important;
    z-index: 0;
    width: 100%;
  }
  .lighten-bg-overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.7);
  }
`

const CloseCont = styled(Container)`
  position: absolute;
  z-index: 12;
  top: 0;
  button {
    border: none;
    background: none;
    font-size: 2.5rem;
    &:hover {
      color: gray;
    }
  }
`
 
interface AecFormSecProps {
  secData: Section;
  showForm: boolean;
  toggleForm: () => void;
}

const AECFormSec = ({ showForm, toggleForm, secData }: AecFormSecProps) => {
  // console.log('secData from form sec: ', secData);
  return (
    <Wrap style={ !showForm ? {display: "none"} : {}}>
      {showForm && <HubspotForm
        portalId='526959'
        formId='13a5514f-fbe9-4673-97a8-2ed2a818d93c'
      />}
      <CloseCont>
        <button className="close-form" aria-label="close form" onClick={() => toggleForm()}><IoMdClose /></button>
      </CloseCont>      
      <WmkImage image={secData.image1} />
      <div className="lighten-bg-overlay" />
    </Wrap>
  )
}
 
export default AECFormSec