import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { RichText, RichTextReact } from 'wmk-rich-text';
import options from '../../../richText/handlers/richTextOptions';
import { WmkImage } from 'wmk-image';
import { Section } from '../../../../classes/Section';
import { Slide } from '../../../../classes/Slide';
 
const squarePctSize = 16;
const linePctWidth = ((100 - (squarePctSize * 3)) / 2)

const Wrap = styled.div`
  color: white;
  padding: 4rem 0;
  .intro-row {
    h2 {
      font-size: 3rem;
    }
    p {
      width: 60%;
      margin: 1rem 0 3rem 0;
    }
  }
  .copy-row {
    h2 {
      text-transform: uppercase;
      font-weight: bold;
      margin: 3rem 0 .5rem 0;
    }
    p {
      // width: 60%;
    }
  }
  .graphic-row {
    width: 100%;
    display: flex;
    align-items: center;
    .box {
      position: relative;
      width: ${squarePctSize}%;
      border: 3px solid white;
      border-radius: 20%;
      padding: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      transition: border .3s ease;
      // neat trick using :after to make sure a div is always square when the width is a percentage
      :after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
      &.active {
        border: 10px solid white;
        transition: border .3s ease;
      }
      .icon-wrap {
        position: absolute;        
        width: 40%;
        height: 40%;
      }
      &.box-1 {
        .icon-wrap {
          top: 20%;
        }
      }
      &.box-2 {
        .icon-wrap {
          top: 24%;
        }
      }
      &.box-3 {
        .icon-wrap {
          top: 14%;
        }
      }
    }
    .line {
      width: ${linePctWidth}%;
      height: 3px;
      background: white;
    }
  }
`
 
const OpenFloodgatesSection = ({ sectionData }: { sectionData: Section }) => {
  const [currItem, setCurrItem] = useState(0)
  const incrementItem: (currItem: number) => void = (currItem) => {
    currItem === 2
      ? setCurrItem(0)
      : setCurrItem(currItem + 1)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      incrementItem(currItem);
    }, 7000);
    return () => {
      clearInterval(interval);
    };
  });

  // console.log("sectionData from flood: ", sectionData)
  const background = sectionData && sectionData.image1 ? sectionData.image1 : null
  const copy = sectionData && sectionData.copy1 ? sectionData.copy1 : null
  const slides = sectionData && sectionData.otherContent ? sectionData.otherContent : null
  // console.log('slides: ', slides);
  const slide1 = slides && slides[0] ? new Slide(slides[0]) : null
  const slide2 = slides && slides[1] ? new Slide(slides[1]) : null
  const slide3 = slides && slides[2] ? new Slide(slides[2]) : null
  // console.log('slides[currItem] && slides[currItem].copy: ', slides[currItem] && slides[currItem].copy);
  return (
    <Wrap style={{backgroundImage: `url(${background.src})`, backgroundSize: 'cover', backgroundColor: "#1F3467"}}>
      <Container>
        <div className="intro-row">
          {copy && <RichTextReact content={copy} options={options} /> }
        </div>
        <div className="graphic-row">
          <button className={currItem === 0 ? "box box-1 active" : "box box-1"} onClick={() => setCurrItem(0)}>
            <div className="icon-wrap">
              {slide1 && <WmkImage image={slide1?.image} />}
            </div>            
          </button>
          <div className="line line-1" />
          <button className={currItem === 1 ? "box box-2 active" : "box box-2"} onClick={() => setCurrItem(1)}>
            <div className="icon-wrap">
              {slide2 && <WmkImage image={slide2?.image} />}
            </div>            
          </button>
          <div className="line line-2" />
          <button className={currItem === 2 ? "box box-3 active" : "box box-3"} onClick={() => setCurrItem(2)}>
            <div className="icon-wrap">
              {slide3 && <WmkImage image={slide3?.image} />}
            </div>            
          </button>
        </div>
        <div className="copy-row">
          {slides[currItem] && slides[currItem].title && <h2>{slides[currItem].title}</h2>}
          {slides[currItem] && slides[currItem].copy && <RichTextReact content={new RichText(slides[currItem].copy)} options={options}/> }
        </div>
      </Container>
    </Wrap>
  )
}
 
export default OpenFloodgatesSection