import * as React from "react";
import styled from "styled-components";
import Theme from "../../../../vars/ThemeOptions";
import { RichText, RichTextReact } from "wmk-rich-text";
import options from "../../../richText/handlers/richTextOptions";
import { Img, WmkImage } from "wmk-image";

const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
  @media only screen and (max-width: 991px) {
    h3 {
      width: 80%;
    }
  }
`;

const CopyWrap = styled.div`
  width: 90%;
  h3 {
    font-size: 2.75rem;
  }
  h3,
  h6 {
    color: ${Theme.hex("primary")};
    font-weight: bold;
  }
  ul {
    // list-style-type: dash;
    margin: 0;
    list-style-type: none;
    li {
      :before {
        content: "-";
        color: ${Theme.hex("primary")};
        display: block;
        float: left;
        text-indent: -10px;
        font-size: 2rem;
        margin-top: -12px;
      }
      p {
        margin-bottom: 4px;
        margin-left: 8px;
      }
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    width: 70%;
    h3 {
      font-size: 2.25rem !important;
    }
  }
`;

const PreviewWrap = styled.div`
  position: absolute;
  top: -150px;
  right: -240px;
  height: 100%;
  width: 590px;
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    width: 500px;
    // right: -300px;
  }
  @media only screen and (max-width: 991px) {
    top: -14vw;
    right: -15vw;
    width: 47vw;
  }
  @media only screen and (max-width: 767px) {
    right: -20vw;
    width: 47vw;
  }
`;

const CopyAndPreview = ({
  copy,
  previewImg
}: {
  copy: RichText;
  previewImg: Img;
}) => {
  // console.log('previewImg: ', previewImg);
  return (
    <Wrap>
      <CopyWrap>
        <RichTextReact content={copy} options={options} />
      </CopyWrap>
      <PreviewWrap>
        {previewImg ? <WmkImage image={previewImg} /> : null}
      </PreviewWrap>
    </Wrap>
  );
};

export default CopyAndPreview;
