import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { RichTextReact } from "wmk-rich-text";
import options from "../../../richText/handlers/richTextOptions";
import { Page } from "../../../../classes/Page";
import { Section } from "../../../../classes/Section";
import { ContObjType } from "../../AECCampaign/AECCampaign";

const Wrap = styled.div`
  font-family: "Rajdhani", sans-serif;
  color: white;
  padding: 8rem 0;
  position: relative;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  .title-row {
    position: relative;
    z-index: 10;
    margin-bottom: 4rem;
    h1 {
      font-size: 4rem;
      font-weight: 700;
      letter-spacing: 10px;
      margin-bottom: -0.5rem;
    }
  }
  .content-row {
    position: relative;
    z-index: 10;
    padding: 0 10vw;
    p {
      font-weight: 500;
    }
    .pdf-link {
      text-align: center;
      a {
        color: white;
        p {
          margin-bottom: 0.5rem;
          white-space: nowrap;
        }
        img {
          border: 3px solid white;
          border-radius: 10px;
          transform: scale(1);
          transition: all 0.3s ease;
        }
        &:hover {
          img {
            transform: scale(1.1);
            transition: all 0.3s ease;
          }
        }
      }
    }
    .buttons-row {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 100;
      a {
        white-space: nowrap;
        padding: 0.25rem 2rem;
        border-radius: 50px;
        margin-right: 1rem;
        background: white;
        color: #223b79;
        border: 2px solid white;
        transition: all 0.3s ease;
        &:hover {
          background: none;
          color: white;
          border: 2px solid white;
          transition: all 0.3s ease;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.row {
      flex-direction: row;
    }
  }
  .lighten-bg-top-overlay {
    position: absolute;
    top: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to bottom,
      white 0%,
      rgba(255, 255, 255, 0.5) 3%,
      rgba(255, 255, 255, 0) 8%
    );
  }
  .darken-bg-overlay {
    position: absolute;
    top: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      rgba(40, 42, 54, 0) 0%,
      rgba(40, 42, 54, 0) 20%,
      rgba(52, 58, 103, 0.2) 50%,
      rgba(52, 58, 103, 0.5) 100%
    );
  }
  @media only screen and (max-width: 991px) {
    .buttons-row {
      flex-direction: column;
      justify-content: center;
      position: relative;
      top: 15rem;
      a {
        margin-bottom: 1rem;
      }
    }
    .pdf-link {
      position: relative;
      top: -7rem;
      left: -6px;
    }
  }
`;

const WaterLPThankYouPage = ({ page }: { page: Page; }) => {
  const content = new Section(page.content.references.find((obj: ContObjType) => { return obj.title === "Water LP's Thank You content" }));
  const copy1 = content?.copy1;
  const copy2 = content?.copy2;
  const image1 = content?.image1;
  return (
    <Wrap
      style={{
        backgroundImage: `url(${image1.src})`,
        backgroundSize: "cover",
        backgroundPosition: "0% 30%",
        backgroundColor: "lightblue"
      }}>
      <Container>
        <Row className="title-row">
          <Col className="centered">
            <RichTextReact content={copy1} options={options} />
          </Col>
        </Row>
        <Row className="content-row">
          <Col md={12} lg={7}>
            <div><RichTextReact content={copy2} options={options} /></div>
            <div className="buttons-row">
              <a href="https://wmkagency.com/work">See Our Work</a>
              <a href="https://wmkagency.com/services">
                Marketing Specialities
              </a>
            </div>
          </Col>
          <Col md={12} lg={1} />
          <Col md={12} lg={4} className="centered">
            <div className="pdf-link centered">
              <a
                href="https://fs.hubspotusercontent00.net/hubfs/526959/WMK_Water%20Management_White%20Page_v6.pdf"
                aria-label="download the Watermark water management white paper"
                download
                target="_blank"
                rel="noopener noreferrer"
                className="centered">
                <p>Click Here to View and Download</p>
                <img
                  src="https://f.hubspotusercontent00.net/hubfs/526959/Water%20White%20Paper%20Cover.png"
                  alt="Automation that Flows pdf cover"
                  width="150"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="lighten-bg-top-overlay" />
      <div className="darken-bg-overlay" />
    </Wrap>
  );
};

export default WaterLPThankYouPage;
