import * as React from "react";
import { WmkLink } from 'wmk-link';
import { RichTextNode } from "wmk-rich-text";

export const inlineHyperlink = (node: RichTextNode, children: React.ReactNode) => {
  //console.log(children)
  return (
    <>
      <WmkLink to={node.data.uri} style={{textDecoration: 'none'}}>
        {children}
      </WmkLink>
    </>
  );
};
