import * as React from "react";
import Layout from "../components/layout/MainLayout";
import WaterLP1 from "../components/routes/WaterCampaign/WaterLP1/WaterLP1";
import WaterLPThankYou from "../components/routes/WaterCampaign/ThankYou/ThankYou";
import WaterLP2 from "../components/routes/WaterCampaign/WaterLP2/WaterLP2";
import AECCampaignPage from "../components/routes/AECCampaign/AECCampaign";
import AECThankYouPage from "../components/routes/AECCampaign/ThankYou/ThankYou";
import { WmkSeo } from "wmk-seo";
import { graphql } from "gatsby";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
import { PageFields } from "./fragments/NodePageFields";
import { ContentfulImageQuery, Img } from "wmk-image";
import { Page } from "../classes/Page";


interface PageNodeProps {
  pageContext: {
    parentPath: string;
    slug: string;
  };
  data: PageQuery;
}

const PageNode = ({ pageContext, data }: PageNodeProps) => {
  // const blocks = data.wordpressPage.acf ? data.wordpressPage.acf.block_page : null;
  // const yoast = data.wordpressPage.yoast_meta
  let RenderThis = null;
  const route = pageContext.slug;
  const page = new Page(data.page);
  const wmkLogo = new Img(data["wmkLogo"]);
  // const wmkLogoWhite = new Img(data["wmkLogoWhite"]);
  switch (route) {
    case "automation-that-flows":
      RenderThis = <WaterLP1 page={page} />;
      break;
    case "purify-your-marketing-pipeline":
      RenderThis = <WaterLP2 page={page} logo={wmkLogo} />;
      break;
    case "automation-that-flows-thank-you":
    case "purify-your-marketing-pipeline-thank-you":
    case "purify-your-marketing-pipeline-thank-you-b-version":
      RenderThis = <WaterLPThankYou page={page} />;
      break;
    case "win-the-whole-build":
      RenderThis = <AECCampaignPage page={page} />;
      break;
    case "win-the-whole-build-thank-you":
      RenderThis = <AECThankYouPage page={page} />;
      break;
    default:
      RenderThis = (
        <h2>
          No Page template for <em>{pageContext.slug}</em> route!{" "}
          {console.log(pageContext)}
        </h2>
      );
  }
  const showHeadFoot =
    route === "links" || route === "purify-your-marketing-pipeline"
      ? false
      : true;
  // console.log("showHeadFoot: ", showHeadFoot);
  const description =
    route === "links"
      ? "Campaigns created by Watermark and ways that we can help you create the right identity for your company"
      : route === "win-the-whole-build"
      ? "Inbound marketing is here to serve as not only the bridge between your firm and potential new customers, but also to educate current clients about your other services in order to win their whole AEC project—not just one or two pieces."
      : data.page.title;

  return (
    <Layout showHeadFoot={showHeadFoot}>
      <WmkSeo.Meta
        title={data.page.title}
        path={"/"}
        slug={data.page.slug}
        description={description}
        ogImageUrl={""}
        twitterImageUrl={""}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      {RenderThis}
    </Layout>
  );
};

export default PageNode;

export interface PageQuery {
  page: PageFields;
  wmkLogo: ContentfulImageQuery;
  wmkLogoWhite: ContentfulImageQuery;
}

export const query = graphql`
  query pageQuery($slug: String!) {
    page: contentfulPages(slug: { eq: $slug }) {
      ...NodePageFields
    }
    wmkLogo: contentfulAsset(title: { eq: "WMK Logo svg" }) {
      title
      gatsbyImageData
      file {
        contentType
        url
      }
    }
    wmkLogoWhite: contentfulAsset(title: { eq: "Logo - White" }) {
      title
      gatsbyImageData
      file {
        contentType
        url
      }
    }
  }
`;
