import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { Section } from "../../../../classes/Section";
import { RichTextReact } from "wmk-rich-text";
import options from "../../../richText/handlers/richTextOptions";
import { WmkImage } from "wmk-image";
 
const Wrap = styled.div`
  position: relative;  
  color: #1F3467;
`

const CopyWrap = styled.div`
  padding: 4rem 0 3rem;
  @media only screen and (max-width: 767px) {
    padding: 4rem 2rem 3rem;
  }
`

const ImgWrap = styled.div`
  position: absolute;
  height: 100%;
  width: 50%;
  right: -5%;
  top: 0;
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    right: -10%;
    opacity: 0.1;
  }
`
 
const Row2 = ({ sectionData }: { sectionData: Section; }) => {
  // console.log("sectionData from flow: ", sectionData)
  const image = sectionData.image1;
  const mainCopy = sectionData.copy1;
  return (
    <Wrap>
      <Container>
        <Row>
          <Col xs={12} md={7}>
            <CopyWrap>
              {mainCopy && <RichTextReact content={mainCopy} options={options} /> }
            </CopyWrap>            
          </Col>
          {/* <Col xs={0} md={5}>
            <ImgWrap>
              {image && <GatsbyImage image={imageGatsby} alt={imageTitle} />}
            </ImgWrap>
          </Col> */}
        </Row>
      </Container>
      <ImgWrap>
        {image && <WmkImage image={image} />}
      </ImgWrap>
    </Wrap>
  )
}
 
export default Row2