import * as React from "react";
import { Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { ContentfulImageQuery } from "wmk-image";
import { Section } from "../../../../classes/Section";
import { Slide } from "../../../../classes/Slide";
import Banner from './WeHelpBanner'
import WorkTile from './WorkTile'
 
const Wrap = styled.div`
  position: relative;
  left: 0%;
`

const AECWeHelpSection = ({ secData }: { secData: Section }) => {
  const bannerCopy = secData.copy1 && secData.copy1;
  // console.log('secData: ', secData);
  return (
    <Wrap 
      >
      <Banner copy={bannerCopy} />
      <Container fluid>
        <Row>
          {secData && secData.otherContent
            ? secData.otherContent.map((piece: { title: string; copy: { raw: string; }; image: ContentfulImageQuery; }, i: number) => {
              const _piece = new Slide(piece);
              const copy = _piece.copy;
              const url = copy.json.content[0].content[0].value;
              const image = _piece.image;
              return(
                <WorkTile img={image} companyName={piece.title} url={url} key={piece.title + i} />
              )
            })
            : null
          }
        </Row>
      </Container>
    </Wrap>
  )
}
 
export default AECWeHelpSection