import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { Section } from "../../../../classes/Section";
import { RichTextReact } from "wmk-rich-text";
import options from "../../../richText/handlers/richTextOptions";
import { WmkImage } from "wmk-image";
 
const Wrap = styled.div`
  position: relative;  
  color: #1F3467;
  background: linear-gradient(to bottom, #284896, #14244B);
`

const CopyWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  padding: 4rem;
  h3 {
    font-weight: bold;
    margin-bottom: 2rem;
  }
`

const ImgWrap = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

const BgImgWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
 
const Row3 = ({ sectionData }: { sectionData: Section }) => {
  // console.log("sectionData from flow: ", sectionData)
  const image = sectionData.image1 && sectionData.image1;
  const dotGrid = sectionData.image2 && sectionData.image2;
  const secTitle = sectionData.title && sectionData.title;
  const mainCopy = sectionData.copy1 && sectionData.copy1;
  return (
    <Wrap>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} style={{position: 'relative'}}>
            <ImgWrap>
              {image && <WmkImage image={image} />}
            </ImgWrap>
          </Col>
          <Col xs={12} md={6}>
            <CopyWrap>
              <h3>{secTitle}</h3>
              {mainCopy && <RichTextReact content={mainCopy} options={options} /> }
              <BgImgWrap>
                {dotGrid && <WmkImage image={dotGrid} />}
              </BgImgWrap>
            </CopyWrap>            
          </Col>
        </Row>
      </Container>
    </Wrap>
  )
}
 
export default Row3