import * as React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Theme from "../../../../vars/ThemeOptions";
import CopyAndPreview from "./CopyAndPreview";
import InboundMarketingSec from "./InboundMarketing";
import AecCtaButton from "../AecCtaButton/AecCtaButton";
import { WmkImage } from "wmk-image";
import { Section } from "../../../../classes/Section";
// import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const Wrap = styled.div`
  position: relative;
  // overflow: hidden;
  .bg-image-wrap {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    .gatsby-image-wrapper {
      height: 100%;
      // opacity: 0;
      opacity: 0.2;
      transition: 1s ease;
    }
    // &.animate {
    //   .gatsby-image-wrapper {
    //     opacity: .2;
    //     transition: 1s ease;
    //   }
    // }
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 1;
`;

const ContentWrap = styled.div<{
  lrPad: string;
  bdrThck: string;
  lrPadMob: string;
}>`
  padding: 1rem ${({ lrPad }) => (lrPad ? lrPad : 0)} 3rem;
  border-left: ${({ bdrThck }) => (bdrThck ? bdrThck : "30px")} solid
    ${Theme.hex("primary")};
  width: auto;
  @media only screen and (max-width: 991px) {
    padding: 1rem ${({ lrPadMob }) => (lrPadMob ? lrPadMob : 0)} 3rem;
  }
  // animation css (currently disabled) - slide in from right once you scroll down far enough
  position: relative;
  left: 0%;
  // left: 120%;
  // transition: left 1s ease .5s;
  // &.animate {
  //   left: 0%;
  //   transition: left 1s ease .5s;
  // }
`;

interface AecLetsBuildSecProps {
  secData: Section;
  lrPad: string;
  lrPadMob: string;
  bdrThck: string;
  toggleForm: () => void;
}

const AECLetsBuildSection = ({
  secData,
  lrPad,
  lrPadMob,
  bdrThck,
  toggleForm
}: AecLetsBuildSecProps) => {
  // const [animate, setAnimate] = useState(false)
  // useScrollPosition(({ currPos }) => {
  //   if (currPos.y < -1) {
  //     setAnimate(true)
  //     // console.log("currPos.y: ", currPos.y);
  //     // console.log('animate: ', animate);
  //   }
  // })
  // console.log('data from letsBuild: ', data);
  const copy1 = secData.copy1 && secData.copy1;
  const copy2 = secData.copy2 && secData.copy2;
  const image1 = secData.image1 && secData.image1;
  const image2 = secData.image2 && secData.image2;

  return (
    <Wrap>
      <StyledContainer>
        <ContentWrap
          // className={animate ? "animate" : ""}
          lrPad={lrPad}
          lrPadMob={lrPadMob}
          bdrThck={bdrThck}>
          <CopyAndPreview copy={copy1} previewImg={image2} />
          <AecCtaButton
            textLine1="UNLOCK YOUR FREE"
            textLine2="INBOUND GUIDE"
            purpose="goToForm"
            toggleForm={toggleForm}
          />
          <InboundMarketingSec copy={copy2} />
        </ContentWrap>
      </StyledContainer>
      <div
        // className={`bg-image-wrap ${animate ? "animate" : ""}`}
        className="bg-image-wrap">
        <WmkImage image={image1} />
      </div>
    </Wrap>
  );
};

export default AECLetsBuildSection;
