import * as React from "react";
// import { useEffect } from 'react'
import styled from 'styled-components'
import MainSec from './TYMainSec'
import WeHelpSec from '../WeHelpSec/WeHelpSec'
import { ContObjType } from "../AECCampaign";
import { Page } from "../../../../classes/Page";
import { Section } from "../../../../classes/Section";
 
const Wrap = styled.div`
  font-family: Rajdhani;
  position: relative;
  top: -5rem;
`
 
const AECThankYouPage = ({ page }: { page: Page }) => {
  const content = page.content
  const mainSecData = new Section(content.references.find((obj: ContObjType) => { return obj.title === "Thank You"}));
  const weHelpSecData = new Section(content.references.find((obj: ContObjType) => { return obj.title === "We Help Companies Build"}));
  return (
    <Wrap>
      <MainSec secData={mainSecData} />
      <WeHelpSec secData={weHelpSecData} />
    </Wrap>
  )
}
 
export default AECThankYouPage