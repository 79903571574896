import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { WmkImage } from "wmk-image";
import { RichTextReact } from "wmk-rich-text";
import { HeroContent } from "../../../../classes/HeroContent";
import options from "../../../richText/handlers/richTextOptions";

const sectionHeight = "45rem";
const buttonWidth = "13rem";
const buttonHeight = "1.8rem";

const Wrap = styled.div<{
  bgUrl: string;
}>`
  // background-image: ${({ bgUrl }) => (bgUrl ? `url(${bgUrl})` : "none")};
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-color: white;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  // max-height: ${sectionHeight};
  .content-wrap {
    position: relative;
    z-index: 10;
    // height: ${sectionHeight};
    padding-top: 5rem;
    padding-bottom: 5rem;
    color: #244084;
    h1 {
      text-transform: uppercase;
      .row-1 {
        font-size: 2.7rem;
        font-weight: 400;
      }
      .row-2 {
        font-size: 6rem;
        font-weight: 700;
        letter-spacing: 12px;
        margin-top: -15px;
      }
    }
    h4 {
      margin: 2rem 0;
    }
    .button-wrap {
      display: flex;
      justify-content: center;
      margin: 4vw 0 1rem 0;
      a {
        height: ${buttonHeight};
        width: ${buttonWidth};
        overflow: hidden;
        border-radius: 20px;
        border: 1px solid lightgray;
        font-weight: 400;
        text-decoration: none;
        :hover {
          text-decoration: none;
          p {
            top: -${buttonHeight};
            transition: top 0.5s ease;
          }
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          height: ${buttonHeight};
          width: ${buttonWidth};
          margin-bottom: 0;
          padding-top: 2px;
          position: relative;
          top: 0;
          transition: top 0.5s ease;
          :first-of-type {
            background: white;
            color: #3e4f8e;
          }
          :last-of-type {
            background: linear-gradient(to right, #ff4a24, #ffc400);
            color: white;
          }
        }
      }
    }
    .copy-wrap {
      font-weight: 400;
    }
  }
  .pipe-bg {
    position: absolute;
    .gatsby-image-wrapper {
      position: relative;
      left: -20vw;
      top: -9vw;
      width: 60vw;
    }
  }
  .lighten-bg-top-bot-overlay {
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to bottom,
      white 0%,
      rgba(255, 255, 255, 0.6) 5%,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0.5) 90%,
      white 100%
    );
  }
  .lighten-entire-bg-overlay {
    display: none;
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
  }
  // .darken-bg-overlay {
  //   position: absolute;
  //   z-index: 0;
  //   height: 100%;
  //   width: 100%;
  //   background: linear-gradient(rgba(40, 42, 54,0) 0%, rgba(40, 42, 54,0) 20%, rgba(52, 58, 103,0.2) 50%, rgba(52, 58, 103,0.5) 100%);
  // }
  @media only screen and (max-width: 991px) {
    background-image: ${({ bgUrl }) => (bgUrl ? `url(${bgUrl})` : "none")};
    background-size: cover;
    background-position: center;
    .lighten-entire-bg-overlay {
      display: block;
    }
    .pipe-bg {
      display: none;
    }
  }
  @media only screen and (min-width: 850px) {
    .bg-wrap {
      .gatsby-image-wrapper {
        height: 30rem;
        object-position: top;
      }
    }
  }
  @media only screen and (min-width: 1800px) {
    .pipe-bg {
      .gatsby-image-wrapper {
        left: -25vw;
        top: -15vw;
      }
    }
  }
`;

const Hero = ({
  heroData,
  scrollElement
}: {
  heroData: HeroContent;
  scrollElement: HTMLDivElement;
}) => {
  const { title, content, background } = heroData;
  const [scrollAction, setScrollAction] = React.useState<{
    func: (element: HTMLDivElement) => void;
  }>();
  const titleArr = title.split(" ");
  const titlePiece1 = titleArr[0] + " " + titleArr[1];
  const titlePiece2 = titleArr[2];
  React.useEffect(() => {
    setScrollAction({
      func: (element: HTMLDivElement) => {
        if (element) {
          const formBox = element.getBoundingClientRect();
          window.scrollTo(formBox.x, formBox.y);
        }
      }
    });
  }, [title]);
  return (
    <Wrap
      bgUrl={background.src}
      // style={{backgroundImage: `url(${background.file.url})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: '0% 0%', backgroundColor: 'white'}}
    >
      <div className="pipe-bg">
        <WmkImage image={background} />
      </div>
      <Container className="content-wrap">
        <Row>
          <Col md={12} lg={7} />
          <Col md={12} lg={5}>
            <h1>
              <div className="row-1">{titlePiece1}</div>
              <div className="row-2">{titlePiece2}</div>
            </h1>
            <div className="button-wrap">
              <a
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  event.preventDefault();
                  scrollAction.func(scrollElement);
                }}>
                <p>Download the Whitepaper</p>
                <p>Get Started Today!</p>
              </a>
            </div>
            <div className="copy-wrap">
              <RichTextReact content={content} options={options} />
            </div>
          </Col>
        </Row>
      </Container>

      <div className="lighten-bg-top-bot-overlay" />
      <div className="lighten-entire-bg-overlay" />
      {/* <div className="darken-bg-overlay" /> */}
    </Wrap>
  );
};

export default Hero;
