import * as React from "react";
import styled from 'styled-components'
import { RichTextReact } from "wmk-rich-text";
import options from "../../../richText/handlers/richTextOptions";
import { Section } from "../../../../classes/Section";
 

const Wrap = styled.div<{
  bgUrl: string;
  textBgUrl: string;
}>`
  background-image: url(${({ bgUrl }) => bgUrl ? bgUrl : ''});
  background-size: cover;
  background-position: 0% 50%;
  height: 35rem;
  position: relative;
  .content-wrap {
    position: relative;
    top: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: white;
    padding: 2rem 0 0 0;
    h2 {
      font-size: 6rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 7px;
      // Fallback: assume this color ON TOP of image
      color: blue;  
      background: url(${({ textBgUrl }) => textBgUrl ? textBgUrl : ''}) no-repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: -1rem;
    }
    h3 {
      position: relative;
      left: -2px;
      font-size: 2.45rem;
      color: #244084;
    }
    a {
      position: relative;
      top: 5rem;
      background: white;
      padding: 6px 3.5rem;
      border: 2px solid white;
      border-radius: 50px;
      color: #223B79;
      transition: all .3s ease;
      &:hover {
        background: none;
        color: white;
        transition: all .3s ease;
        text-decoration: none;
      }
    }
  }
`
 
const ExploreCTA = ({ sectionData }: { sectionData: Section }) => {
  //console.log("explore sectionData: ", sectionData)
  const { image1, image2 } = sectionData;
  const bgUrl = image1.src;
  const textBgUrl = image2.src;
  return (
    <Wrap bgUrl={bgUrl} textBgUrl={textBgUrl}>
      <div className="content-wrap">
        <div className="title-bar">
          {sectionData.copy1 && <RichTextReact content={sectionData.copy1} options={options} /> }
        </div>
      </div>
    </Wrap>
  )
}
 
export default ExploreCTA