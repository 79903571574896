import * as React from "react";
import styled from 'styled-components'
import { Section } from '../../../../classes/Section';
import { RichTextReact } from 'wmk-rich-text';
import options from '../../../richText/handlers/richTextOptions';
 
const Wrap = styled.div`
  padding: 4rem 2rem 3rem;
  background: #284896;
  color: white;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-bottom: 1rem;
  }
  a {
    display: inline-block;
    background: white;
    color: #284896;
    padding: 1rem 3rem;
    border-radius: 500px;
    transform: scale(1);
    transition: all .3s ease;
    &:hover {
      text-decoration: none;
      transform: scale(1.1);
      transition: all .3s ease;
    }
  }
`
 
const Row4 = ({ sectionData }: { sectionData: Section; }) => {
  const title = sectionData.title;
  const copy = sectionData.copy1;
  return (
    <Wrap>
      <h2>{title}</h2>
      <RichTextReact content={copy} options={options} />
    </Wrap>
  )
}
 
export default Row4