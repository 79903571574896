import * as React from "react";
import styled from "styled-components";
import Hero from "./Hero";
import IncreaseFlow from "./IncreaseFlow";
import OpenFloodgates from "./OpenFloodgates";
import Explore from "./ExploreCTA";
import { HeroContent } from "../../../../classes/HeroContent";
import { Section } from "../../../../classes/Section";
import { Page } from "../../../../classes/Page";
import { HeroFields } from "../../../../node/fragments/NodeHeroFields";
import { SectionFields } from "../../../../node/fragments/NodeSectionFields";

const Wrap = styled.div`
  font-family: "Rajdhani", sans-serif;
`;

const WaterLP1 = ({ page }: { page: Page }) => {
  const [scrollElement, setScrollElement] = React.useState<HTMLDivElement>();
  // console.log("data: ", data)
  const content = page.content;
  // console.log('content: ', content);
  const heroData = new HeroContent(content.references[0] as HeroFields);
  const increaseFlowData = new Section(content.references[1] as SectionFields);
  const floodgatesData = new Section(content.references[2] as SectionFields);
  const exploreData = new Section(content.references[3] as SectionFields);
  const handleElement = (element: HTMLDivElement) => {
    setScrollElement(element);
  };
  return (
    <Wrap>
      <Hero heroData={heroData} scrollElement={scrollElement} />
      <IncreaseFlow
        sectionData={increaseFlowData}
        handleElement={handleElement}
      />
      <OpenFloodgates sectionData={floodgatesData} />
      <Explore sectionData={exploreData} />
    </Wrap>
  );
};

export default WaterLP1;
