import * as React from "react";
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import Theme from '../../../vars/ThemeOptions'
import { HeroContent } from "../../../classes/HeroContent";
import { RichTextReact } from "wmk-rich-text";
import options from "../../richText/handlers/richTextOptions";
 
const ContentWrap = styled.div<{
  lrPad: string;
  lrPadMob: string;
  bdrThck: string;
  contWd: string;
}>`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ contWd }) => contWd ? contWd : "auto"};
  padding: 2rem ${({ lrPad }) => lrPad ? lrPad : 0} 1rem;
  border-top: ${({ bdrThck }) => bdrThck ? bdrThck : "30px"} solid ${Theme.hex('primary')};
  border-left: ${({ bdrThck }) => bdrThck ? bdrThck : "30px"} solid ${Theme.hex('primary')};
  border-right: ${({ bdrThck }) => bdrThck ? bdrThck : "30px"} solid ${Theme.hex('primary')};
  h2, h1 {
    text-transform: uppercase;
  }
  h2 {
    font-size: 3.75rem;
    font-weight: 300;
    margin: 0;
    // color: ${Theme.hex('secondary')};
  }
  h1 {
    font-size: 4.75rem;
    font-weight: bold;
    color: ${Theme.hex('primary')};
    position: relative;
    top: -25px;
    left: 5px;
  }
  p {
    width: 95%;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    h2 {
      font-size: 3rem;
    }
    h1 {
      font-size: 3.75rem;
      top: -15px;
      left: 3px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding: 2rem ${({ lrPadMob }) => lrPadMob ? lrPadMob : 0} 1rem;
    h2 {
      font-size: 2.6rem;
    }
    h1 {
      font-size: 3.3rem;
      top: -11px;
      left: 2px;
    }
    p {
      width: 100%;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: 2rem ${({ lrPadMob }) => lrPadMob ? lrPadMob : 0} 1rem;
    h2 {
      font-size: 1.8rem;
    }
    h1 {
      font-size: 2.25rem;
      top: -11px;
      left: 2px;
    }
    p {
      width: 100%;
    }
  }
  @media only screen and (max-width: 575px) {
    padding: 2rem ${({ lrPadMob }) => lrPadMob ? lrPadMob : 0} 1rem;
    h2 {
      font-size: 4.9vw;
    }
    h1 {
      font-size: 6vw;
      top: -5px;
      left: 2px;
    }
    p {
      width: 100%;
    }
  }
`

interface AecHeroProps {
  heroData: HeroContent;
  lrPad: string;
  lrPadMob: string;
  bdrThck: string;
  contWd: string;
}

const AECCampaignHero = ({ heroData, lrPad, lrPadMob, bdrThck, contWd }: AecHeroProps) => {
  const content = heroData.content && heroData.content;
  return (
    <div>
      <Container>
        <ContentWrap lrPad={lrPad} lrPadMob={lrPadMob} bdrThck={bdrThck} contWd={contWd}>
          <RichTextReact content={content} options={options} />
        </ContentWrap>          
      </Container>
    </div>
  )
}
 
export default AECCampaignHero