import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { Img, WmkImage } from "wmk-image";
import { Section } from "../../../../classes/Section";
import { RichTextReact } from "wmk-rich-text";
import options from "../../../richText/handlers/richTextOptions";
 
const previewHeight = 17;
const previewWidth = previewHeight * 0.7;
const Wrap = styled.div`
  position: relative;  
  color: white;
  padding: 4rem 0;
`
const LogoWrap = styled.div`
  position: relative;
  top: -2rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
`
const CopyWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3, h4, h5 {
    text-transform: uppercase;
  }
  h5 {
    font-weight: normal;
    margin-bottom: 2rem;
  }
  h3 {
    font-weight: normal;
    font-size: 2.25rem;
    margin-bottom: -3px;
  }
  h4 {
    font-size: 2.2rem;
    font-weight: bold;
    margin-bottom: -3px;
  }
  p:first-of-type {
    font-size: .9rem;
    margin-bottom: 2rem;
  }
  a {
    display: none;
  }
  button {
    // display: inline-block;
    width: 15rem;
    background: white;
    color: #284896;
    padding: 1rem 0;
    border-radius: 500px;
    transform: scale(1);
    transition: all .3s ease;
    &:hover {
      text-decoration: none;
      transform: scale(1.1);
      transition: all .3s ease;
    }
  }
  @media only screen and (max-width: 767px) {
    align-items: center;
    padding: 0 2rem;
    a {
      margin: 2rem 0;
    }
  }
`
const PreviewWrap = styled.div`
  display: flex;
  justify-content: center;
  .gatsby-image-wrapper {
    height: ${previewHeight}rem;
    width: ${previewWidth}rem;
  }
`
const BackgroundWrap = styled.div`
  position: absolute;
  z-index: -2;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      height: 100%;
    }
  }
`
const BlueGradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(31, 52, 103, .92) 0%, rgba(31, 52, 103, 0) 75%);
`
 
const Row1 = ({ sectionData, formOpen, toggleForm, logo }: { sectionData: Section; formOpen: boolean; toggleForm: ()=>void; logo: Img; }) => {
  // console.log("data from flow: ", data)
  const background = sectionData.image1;
  const preview = sectionData.image2;
  const secTitle = sectionData.title
  const mainCopy = sectionData.copy1;

  const logoTitle = logo.title;
  const logoUrl = logo.src;
  return (
    <Wrap>
      <LogoWrap>
        <a href="wmkagency.com" target='_blank' rel='noopener noreferrer'>
          <img src={logoUrl} alt={logoTitle} />
        </a>        
      </LogoWrap>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <CopyWrap>
              <h5>{secTitle}</h5>
              {mainCopy && <RichTextReact content={mainCopy} options={options} /> }
              <button onClick={() => toggleForm()}>
                Download Now
              </button>
            </CopyWrap>            
          </Col>
          <Col xs={12} md={6}>
            <PreviewWrap>
              {preview && <WmkImage image={preview} />}
            </PreviewWrap>
          </Col>
        </Row>
      </Container>
      <BackgroundWrap>
        {background && <WmkImage image={background} />}
        <BlueGradient />
      </BackgroundWrap>
    </Wrap>
  )
}
 
export default Row1