import { RichText } from "wmk-rich-text";
import { PageFields } from "../node/fragments/NodePageFields";

export class Page {
  title: string;
  content: RichText;
  meta: {
    description: string;
  };
  campaign: {
    slug: string;
  };
  slug: string;
  constructor(node: PageFields) {
    const _node = { ...node };
    this.title = _node.title;
    this.content = new RichText(_node.content);
    this.campaign = _node.campaign;
    this.meta = _node.meta;
    this.slug = _node.slug;
  }
}
