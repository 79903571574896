import * as React from "react";
import styled from 'styled-components'
import Theme from '../../../../vars/ThemeOptions'
import AecCtaButton from "../AecCtaButton/AecCtaButton";
import { RichTextReact } from "wmk-rich-text";
import options from "../../../richText/handlers/richTextOptions";
import { Section } from "../../../../classes/Section";
import { WmkImage } from "wmk-image";
 
const Wrap = styled.div`
  position: relative;
  .content-wrap {
    position: relative;
    z-index: 2;
    padding: 10rem 10vw 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 4rem;
      font-weight: bold;
      color: ${Theme.hex('primary')};
      text-transform: uppercase;
      letter-spacing: 8px;
    }
    p {
      text-align: center;
      max-width: 35rem;
    }
  }
  .gatsby-image-wrapper {
    position: absolute !important;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .lighten-bg-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.75);
  }
`
 
const AECTYMainSec = ({ secData }: { secData: Section }) => {
  // console.log('secData from ty main sec: ', secData);
  const { copy1, copy2, image1 } = secData;
  const ctaCopy = copy2 && copy2.json ? copy2.json.content[0].content[0].value : null
  return (
    <Wrap>
      <div className="content-wrap">
        <RichTextReact content={copy1} options={options} />
        <br />
        <AecCtaButton purpose="fileDownload" textLine1="DOWNLOAD YOUR" textLine2="INBOUND BLUEPRINT" />
        <br />
        <AecCtaButton purpose="bookMeeting" textLine1={ctaCopy ? ctaCopy : "Book a Meeting With Us"} />
      </div>
      <WmkImage image={image1} />
      <div className="lighten-bg-overlay" />
    </Wrap>
  )
}
 
export default AECTYMainSec