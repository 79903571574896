import { Img } from "wmk-image";
import { RichText } from "wmk-rich-text";
import { SectionFields } from "../node/fragments/NodeSectionFields";


export class Section {
  __typename: string;
  title: string;
  copy1?: RichText;
  copy2?: RichText;
  image1?: Img;
  image2?: Img;
  otherContent?: { [key: string]: any; __typename: "ContentfulSlide" | "ContentfulButton"; };
  constructor(node: SectionFields) {
    const _node = { ...node };
    this.__typename = _node.__typename;
    this.title = _node.title;
    this.copy1 = _node.copy1 && new RichText(_node.copy1);
    this.copy2 = _node.copy2 && new RichText(_node.copy2);
    this.image1 = _node.image1 && new Img(_node.image1);
    this.image2 = _node.image2 && new Img(_node.image2);
    this.otherContent = _node.otherContent && _node.otherContent;
  }
}