import { RichText } from "wmk-rich-text";
import { Img } from "wmk-image";
import { SlideFields } from "../node/fragments/NodeSlideFields";

export class Slide {
  title: string;
  copy?: RichText;
  image?: Img;
  constructor(node: SlideFields) {
    const _node = { ...node };
    this.title = _node.title;
    this.copy = _node.copy && new RichText(_node.copy);
    this.image = _node.image && new Img(_node.image);
  }
}

// not used yet because it clashed with the otherContent typing in NodeSectionFields
// export class Slides {
//   list: Slide[];
//   length: number;
//   constructor(_slides: SlideFields[]) {
//     const slidesDeepCopy = Array.isArray(_slides) ? [..._slides] : [];
//     const slides = slidesDeepCopy.map((slide) => new Slide(slide));
//     this.list = slides;
//     this.length = slides.length;
//   }
// }