import * as React from "react";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { EmbeddedBlock, RichTextNode, RichTextOptions } from "wmk-rich-text";
import {
  blockAsset,
  blockEntry,
  blockHeading2,
  blockQuote,
  inlineAssetHyperlink,
  inlineEntryHyperlink,
  inlineHyperlink
} from ".";
import WaterHero from "../../routes/WaterCampaign/WaterLP1/Hero";
import { HeroContent } from "../../../classes/HeroContent";

export const locale = "en-US";

const blockHash = {
  ContentfulHeroContent: (props) => {
    const n = new HeroContent(props);
    // console.log(props, n);
    return <WaterHero {...n} />;
  }
};

const options: RichTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: RichTextNode) => blockAsset(node),
    [BLOCKS.EMBEDDED_ENTRY]: (node: RichTextNode) => {
      // console.log(node);
      const entry = new EmbeddedBlock(node, blockHash);
      return entry.render();
    },
    [BLOCKS.HEADING_2]: (node: RichTextNode, children: React.ReactNode) =>
      blockHeading2(node, children),
    [BLOCKS.QUOTE]: (node: RichTextNode) => blockQuote(node),
    [INLINES.ASSET_HYPERLINK]: (
      node: RichTextNode,
      children: React.ReactNode
    ) => inlineAssetHyperlink(node, children),
    [INLINES.ENTRY_HYPERLINK]: (
      node: RichTextNode,
      children: React.ReactNode
    ) => inlineEntryHyperlink(node, children),
    [INLINES.HYPERLINK]: (node: RichTextNode, children: React.ReactNode) =>
      inlineHyperlink(node, children)
  }
};

export default options;
