import * as React from "react";
import { Col } from 'react-bootstrap'
import { WmkLink } from 'wmk-link'
import styled from 'styled-components'
import { Img, WmkImage } from "wmk-image";
 
const WrapCol = styled(Col)`
  position: relative;
  width: 100%;
  // height: 16rem;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
  a {
    p {
      position: absolute;
      z-index: 2;
      bottom: .5rem;
      margin-bottom: 0;
      color: white;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.3rem;
    }
    .gatsby-image-wrapper {
      transform: scale(1);
      transition: all .4s ease;
      width: 100%;
      height: 100%;
      img {
        position: relative;
        z-index: 0;
        // transform: scale(1);
        // transition: all .4s ease;
      }
    }
    .gatsby-image-wrapper > div {
      display: none !important;
    }
    .darken-overlay {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.4);
      transition: all .4s ease;
    }
    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.1);
        transition: all .4s ease;
        img {
          // transform: scale(1.1);
          // transition: all .4s ease;
        }
      }
      .darken-overlay {
        background: rgba(224,118,30,0.75);
        transition: all .4s ease;
      }
    }
  }
`
 
const WorkTile = ({ img, companyName, url }: { img: Img; companyName: string; url: string; }) => {
  return (
    <WrapCol>
      <WmkLink to={url} target="_blank">
        <WmkImage image={img} />
        <div className="darken-overlay" />
        <p>{companyName}</p>
      </WmkLink>
    </WrapCol>
  )
}
 
export default WorkTile