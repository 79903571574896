import * as React from "react";
import styled from 'styled-components'
import { RichText, RichTextReact } from "wmk-rich-text";
import options from "../../../richText/handlers/richTextOptions";
 
const Wrap = styled.div`
  margin-top: 5rem;
  h5 {
    font-size: 1.7rem;
    margin: 0 0 -.5rem 0;
  }
  h2 {
    text-transform: uppercase;
    font-size: 4.05rem;
    letter-spacing: 2px;
    position: relative;
    left: -2px;
  }
`
 
const InboundMarketingSec = ({ copy }: { copy: RichText }) => {
  return (
    <Wrap>
      <RichTextReact content={copy} options={options} />
    </Wrap>
  )
}
 
export default InboundMarketingSec